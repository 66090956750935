import { Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  useForgottenPasswordMutation,
  useLoginMutation
} from "../../generated/graphql";
import { handleError } from "../../util/helpers";
import useAuth from "../../util/useAuth";
import { usePasswordExpiredModalContext } from "../Commons/PasswordExpiredModal";
import { usePasswordReminderModalContext } from "../Commons/PasswordReminderModal";
import Button, { ButtonSizes } from "./Button";
import Column from "./Column";
import { useErrorModalContext } from "./ErrorModal";
import FormError from "./FormError";
import FormGroup from "./FormGroup";
import InputField from "./InputField";
import LinkButton from "./LinkButton";
import Modal from "./Modal";
import ModalBody from "./ModalBody";
import ModalContent from "./ModalContent";
import ModalFooter from "./ModalFooter";
import ModalHeader from "./ModalHeader";
import Row from "./Row";
export interface Callback {
  callbackFn: () => void;
}

interface LoginModalContext {
  closeLoginModal: () => void;
  isLoginModalOpen: boolean;
  loginCallback: Callback | null;
  openLoginModal: (callback?: Callback) => void;
}

export const LoginModalContext = React.createContext<LoginModalContext>({
  closeLoginModal: () => null,
  isLoginModalOpen: false,
  loginCallback: null,
  openLoginModal: () => null
});

export const LoginModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [isLoginModalOpen, setIsModalOpen] = React.useState(false);
  const [loginCallback, setLoginCallback] = React.useState<Callback | null>(
    null
  );

  const closeLoginModal = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const openLoginModal = React.useCallback((callback?: Callback) => {
    setIsModalOpen(true);
    setLoginCallback(callback || null);
  }, []);

  return (
    <LoginModalContext.Provider
      value={{
        closeLoginModal,
        isLoginModalOpen,
        loginCallback,
        openLoginModal
      }}
    >
      {children}
    </LoginModalContext.Provider>
  );
};

export const useLoginModalContext = (): LoginModalContext =>
  React.useContext(LoginModalContext);

interface Props {
  isOpen: boolean;
  loginCallback: Callback | null;
  onClose: any;
}

const LoginModal: React.FC<Props> = ({ isOpen, loginCallback, onClose }) => {
  const [forgottenPassword] = useForgottenPasswordMutation();
  const [successMessage, setSuccessMessage] = React.useState("");
  const { openErrorModal } = useErrorModalContext();
  const [login] = useLoginMutation();
  const { t } = useTranslation(["login", "commons"]);
  const { setLoginState } = useAuth();
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
  const [forgotten, setForgotten] = React.useState(false);
  const constructError = React.useCallback(
    (path: string, type: string) => {
      switch (type) {
        case "BadCredentials":
        case "BadPassword":
          return t("commons:errorBadCredentials");
        case "TooManyAttempts":
          return t("commons:errorTooManyAttempts");
        default:
          return `${t("commons:error" + type)}`;
      }
    },
    [t]
  );
  const { openPasswordExpiredModal } = usePasswordExpiredModalContext();
  const { openPasswordReminderModal } = usePasswordReminderModalContext();

  const handleCloseModal = () => {
    onClose();
  };

  const handleCloseModalAfterSubmit = () => {
    onClose();
    if (loginCallback) {
      loginCallback.callbackFn();
    }
  };

  const renderLogin = () => {
    return (
      <Formik
        initialValues={{ password: "", username: "" }}
        onSubmit={(values: any) => {
          handleSubmit(values);
        }}
      >
        <Form>
          {forgotten ? (
            <ModalHeader title={t("commons:forgottenPassword")} />
          ) : (
            <ModalHeader title={t("titleLogin")} />
          )}

          <ModalBody>
            <FormError errors={validationErrors} />
            {forgotten && <p>{t("commons:resetLinkText")}</p>}
            <Row>
              <Column column={3} isFirstColumn={true}>
                <FormGroup hasLargeMarginBottom={true}>
                  <label>{t("labelUsername")}</label>
                  <Field component={InputField} name="username" type="text" />
                </FormGroup>
              </Column>
            </Row>
            {successMessage && (
              <p style={{ color: "#5ed49d" }}>{successMessage}</p>
            )}
            {forgotten || (
              <Row>
                <Column column={3} isFirstColumn={true}>
                  <FormGroup hasLargeMarginBottom={true}>
                    <label>{t("labelPassword")}</label>
                    <Field
                      component={InputField}
                      name="password"
                      type="password"
                    />
                  </FormGroup>
                </Column>
              </Row>
            )}

            <Row>
              <Column column={3} isFirstColumn={true}>
                {forgotten ? (
                  <LinkButton onClick={() => setForgotten(false)}>
                    {t("titleLogin")}
                  </LinkButton>
                ) : (
                  <LinkButton onClick={() => setForgotten(true)}>
                    {t("commons:forgottenPassword")}
                  </LinkButton>
                )}
              </Column>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleCloseModal}
              size={ButtonSizes.XSMALL_SECONDARY}
              type="button"
            >
              {t("buttonCancel")}
            </Button>
            <Button size={ButtonSizes.XSMALL} type="submit">
              {t("buttonReady")}
            </Button>
          </ModalFooter>
        </Form>
      </Formik>
    );
  };

  const resetLinkSent = () => {
    setSuccessMessage(t("commons:emailSent"));
    setTimeout(() => {
      onClose();
    }, 2000);
  };

  const handleSubmit = async (values: any) => {
    if(forgotten) {
      try {
        await forgottenPassword({
          variables: { email: values.username }
        });
        setValidationErrors([]);
        resetLinkSent();
      } catch(err) {
        setValidationErrors([t("commons:errorInvalidEmail")]);
      }
    } else {
      try {
        const response = await login({ variables: values });

        if (response.data && response.data.login) {
          const { passwordExpireInDays } = response.data.login;

          setLoginState(response.data.login);

          if (passwordExpireInDays > 0) {
            openPasswordReminderModal();
          }
          if (passwordExpireInDays < 0) {
            openPasswordExpiredModal();
          }
          handleCloseModalAfterSubmit();
        }
      } catch(err) {
        handleError(
          err,
          openErrorModal,
          t,
          undefined,
          constructError,
          setValidationErrors
        );
      }
    }
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} zIndex={1060}>
        <ModalContent>{renderLogin()}</ModalContent>
      </Modal>
    </React.Fragment>
  );
};

export default LoginModal;
